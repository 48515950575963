.logo-cont{
    transition: all .2s ease-in-out;
    width:100%;
    text-align:center;
    &.min{
        width: 0;
    }
    img{
        margin-top: 1em;
        width: 10em;
        display: inline-block;
        transition: all .2s ease-in-out;
    }
}
.app-cont{
    .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
        background-color: #fd1602;
        color: #fff;
        svg{
            color: #fff;
        }
    }
}

@media screen and (max-width: 500px) {
    .drawerContent {
        padding: 0!important;
    }
  }
