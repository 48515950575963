
.auth-cont{
  padding-top: 12vh;
  display: block;
  height: 100vh;
  width: 100%;
  background-color: #DFDBE5;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='152' height='152' viewBox='0 0 152 152'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='temple' fill='%239C92AC' fill-opacity='0.4'%3E%3Cpath d='M152 150v2H0v-2h28v-8H8v-20H0v-2h8V80h42v20h20v42H30v8h90v-8H80v-42h20V80h42v40h8V30h-8v40h-42V50H80V8h40V0h2v8h20v20h8V0h2v150zm-2 0v-28h-8v20h-20v8h28zM82 30v18h18V30H82zm20 18h20v20h18V30h-20V10H82v18h20v20zm0 2v18h18V50h-18zm20-22h18V10h-18v18zm-54 92v-18H50v18h18zm-20-18H28V82H10v38h20v20h38v-18H48v-20zm0-2V82H30v18h18zm-20 22H10v18h18v-18zm54 0v18h38v-20h20V82h-18v20h-20v20H82zm18-20H82v18h18v-18zm2-2h18V82h-18v18zm20 40v-18h18v18h-18zM30 0h-2v8H8v20H0v2h8v40h42V50h20V8H30V0zm20 48h18V30H50v18zm18-20H48v20H28v20H10V30h20V10h38v18zM30 50h18v18H30V50zm-2-40H10v18h18V10z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"), radial-gradient(#CAD4E3 20%, #7894BA 80%);
}

.MuiDialogTitle-root{
  background-color: #3f51b5;
  color: #fff;
  h2{
    font-weight: 800!important;
  }

}

table.table-style{
  min-width: 100%;
  border-collapse: collapse;
  border: solid 2px #3f51b5;
  thead{
    th{
      text-align: center;
      background-color: #3f51b5;
      color: #fff;
      font-weight: 700;
      padding: .5em;
    }
  }
  tbody{
    tr{
      &:nth-child(even){
          background-color: hsla(229, 45%, 59%, 0.1);
      }
      td,th{
        text-align: center;
        padding: .5em;
        font-weight: 700;
      }
    }
  }

}

.MuiTextField-root,
.MuiFormControl-root{
  .MuiOutlinedInput-root.Mui-disabled{
    color: black;
    .MuiOutlinedInput-notchedOutline{
      // border-color: #e7e6e6;
      border: none;
      border-bottom: solid 1px #e2e2e2;
      border-radius: 0;
    }
    svg.MuiSelect-icon{
      display: none;
    }
  }
}