  .animated-multi-cont{
    .Select {
          width: 444px !important;
          margin-right: 5px;
          z-index: 0 !important;
      }
      
      .Select.is-open {
          z-index: 1 !important;
      }
      .Select-menu {
          position: fixed !important;
          width: 440px !important;
          margin: 1px;
      }
      [class*="-Menu"] {
        background: #fafafa;
        border: solid 1px #c2c0c0;
        max-height: 10em;
      }
  }
