  .loading-spin-cont {
    .spin-shade{
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 9998;
      background-color: rgba(194, 194, 194, 0.28);
    }
    .spinner-cont{
        position: fixed;
        top: 35%;
        z-index: 9999;
        left: 41%;
        padding: 3em 3em 1em;
        background-color: #3f51b5;
        border-radius: 1em;
        h1{
          margin-top: 2em;
          width: 100%;
          text-align: center;
          display: inline-block;
          color: #ece5e5;
        }
        .revolver {
          height: 80px;
          width: 80px;
          margin: auto;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          position: relative;
          background: #d4d2d2;
          transform: rotate(30deg);
          animation: rotate 6.5s cubic-bezier(0.74, 0.61, 0, 1.33) infinite;
          span{
            height: 40px;
            width: 30px;
            border-radius: 5px;
            transform-origin: bottom center;
            position: absolute;
            display: flex;
            justify-content: center;
            &:after,
            &:before{
              content: " ";
              display: block;
              background: #3f51b5;
              height: 26px;
              width: 26px;
              border-radius: 13px;
              margin-top: -18px;
            }
            &:before {
              position: absolute;
              height: 12px;
              width: 12px;
              margin-top: 11px;
              right: -5px;
              background: black;
              border: 3px solid black;
            }
          }
        }
    }
  }
  

@for $i from 1 through 6 {
.revolver span:nth-of-type(#{$i}) {
    transform: rotateZ(calc(60deg * #{$i}));
  }
}

@keyframes rotate {
  0% {
    transform: rotate(30deg);
  }
  16% {
    transform: rotate(90deg);
  }
  32% {
    transform: rotate(150deg);
  }
  48% {
    transform: rotate(210deg);
  }
  64% {
    transform: rotate(270deg);
  }
  82% {
    transform: rotate(330deg);
  }
  100% {
    transform: rotate(390deg);
  }
}
