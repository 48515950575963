.my-points-cont{
    .MuiTabs-vertical{
        div[role=tabpanel]{
            width: 100%;
        }
        .MuiTabs-scroller{
            min-width: 10em;
        }
    }
    button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit{
            transition: all .3s ease-in-out;
            &.Mui-selected{
                background-color: #fd1602;
                color: #fff;
                font-weight: 700;
                min-width: 9em!important;
            }
        }
        .MuiTabs-indicator{
            background-color: #2500f5;
            // width: .5em;
        }
    }
.my-info-bar-cont{
    margin: 1em 0 2em;
}