.form-cont{
    >div{
        display: flex;
        width: 100%;
        min-width: 25em;
        >div{
            flex: 1;
            margin: .4em .3em;
        }
    }
}

.MuiDialog-root{
    &.adminProfile{
        .MuiDialog-paperWidthSm{
            min-width: 80%;
        }
    }
}