.card-cont{
    margin: 2em;
    text-align: center;
    color: #30307d;
    p{
        font-size: .7em;
        font-weight: 600;
    }
    .front-card{
        position: relative;
        margin: auto;
        border: solid 2px #30307d;
        border-radius: .5em;
        width: 20em;
        padding: 0 .3em 0;
        background-color: #e5e2e2;
        .bg{
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background-image: url(../../../../../../../../src/img/nroilogo.png);
            background-position: top center;
            background-size: cover;
            filter: blur(11px);
            z-index: 0;
        }
        .card-content{
            display: flex;
            position: relative;
            z-index: 1;
            div{
                &:first-child{
                    max-width: 33%;
                    img{
                        background-color: #fff;
                        border-radius: .3em;
                        max-width: 70%;
                    }
                    p{
                        font-weight: 600;
                    }
                }
                &:last-child{
                    img{
                        max-width: 25%;
                    }
                    h1{
                        line-height: 1;
                        margin: 0;
                        margin-bottom: .2em;
                        font-size: 1.6em;
                    }
                }
            }
        }
    }
}