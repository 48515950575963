
.table-data-cont{
    .MuiPaper-root{
        padding: 0 .3em;
        box-shadow: none;
        table.MuiTable-root{
            thead{
                th{
                    background-color: #3f51b5;
                    color: #fff;
                    font-weight: 700;
                }
            }
            tbody{
                tr{
                    &:nth-child(even){
                        background-color: hsla(229, 45%, 59%, 0.1);
                    }
                }
            }
        }
    }
    .tbl-active-icon{
        display: flex;
        flex-direction: column;
        color: green;
        align-content: center;
        text-align: center;
        svg{
            margin: auto;
        }
        p{
            margin: auto .1em;
            font-size: .7em;
            font-weight: 700;
        }
        &.notActive{
            color: Red;
        }
        &.na{
            color: grey;
        }
        &.view{
            color: #5052c9;
        }
    }
    .table-link{
        font-size: .7em;
        font-weight: 700;
    }
}